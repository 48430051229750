import React from "react"
import { Link } from "gatsby"
import { SectionTitle, SectionTitle2 } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"
const ToResidensInMexico = () => {
  return (
    
      <section className="container to-residents-mexico">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="to-residents-mexico__for-to-residents-mexico">
              <SectionTitle>メキシコにお住まいの方へ</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="to-residents-mexico__for-to-residents-mexico--box">
                <p>
                  クイックグローバルメキシコはメキシコのアグアスカリエンテス州を拠点に、メキシコ各地の日系企業でのお仕事を専門に紹介している日系人材紹介会社です。
                  メキシコに進出する日系企業は年々増え続けており、特にスペイン語スキルをお持ちの方、就労資格をお持ちの方、メキシコでの就労経験をお持ちの方は常に売り手市場です。就職・転職を検討されている方、将来のキャリアについて悩まれている方は一度弊社のコンサルタントとお話してみませんか？
                  <br /> 弊社でのサポートの流れは「
                  <Link to="/process">お仕事紹介の流れ</Link>
                  」をご覧ください。 <br />
                  <small>※弊社サービスのご利用は無料です。</small>
                </p>
              </div>
            </div>
            <div className="to-residents-mexico__for-to-residents-mexico">
              <SectionTitle2>
                クイックグローバルメキシコを使うメリットは？
              </SectionTitle2>
              <hr className="u-line-bottom" />
              <div className="to-residents-mexico__for-to-residents-mexico--box">
                <ul className="to-residents-mexico__list">
                  <li> 在墨日系企業の求人をまとめて検索できる</li>
                  <li>地域別の求人情報がわかる</li>
                  <li>自分では見つけられなかった求人への応募ができる</li>
                  <li>語学能力や自分の適性にあった仕事を紹介してもらえる</li>
                  <li>自分のスキルに見合った給与相場を教えてもらえる</li>
                  <li>
                    専門のコンサルタントがマッチする求人を紹介してくれるので無駄がない
                  </li>
                  <li>
                    未経験の職種へのチャレンジやキャリアパスの相談ができる
                  </li>
                  <li>就職後も相談にのってもらえる</li>
                  <li>必要に応じて代わりに企業と交渉してくれる</li>
                </ul>
              </div>
            </div>

            <div className="to-residents-mexico__for-to-residents-mexico">
              <SectionTitle2>どういった方にオススメ？</SectionTitle2>
              <hr className="u-line-bottom" />
              <div className="to-residents-mexico__for-to-residents-mexico--box">
                <ul className="to-residents-mexico__list">
                  <li> メキシコ国内で転職したい</li>
                  <li>現在のメキシコ国内の転職マーケットを知りたい</li>
                  <li>メキシコで就職、転職する上でのアドバイスが欲しい</li>
                  <li>インターンシップを終えたが引き続きメキシコで働きたい</li>
                  <li>スペイン語留学中の学生で、卒業後メキシコで就労したい</li>
                  <li>
                    駐在員として渡墨し、帰国指示が出ているが引き続きメキシコで働きたい
                  </li>
                  <li>駐在員の帯同で渡墨し、現地就職に興味がある</li>
                  <li>メキシコ人と婚姻していて、就労に興味がある</li>
                </ul>
                <hr className="u-line-bottom" />
                <p>
                  メキシコでお仕事をお探しなら、現地に精通したコンサルタントがお答えします。
                  まずはお気軽にお問い合わせください。
                </p>
                <a
                  className="job-button"
                  href="https://www.919mexico.com/job-form/"
                >
                  <div className="arrow right"></div>
                  <span className="button-text">今すぐ無料相談</span>
                </a>
              </div>
            </div>

            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>
    
  )
}

export default ToResidensInMexico


export const Head = () => {
  return (
    <Seo
      title="メキシコにお住まいの方へ"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/to-residents-in-mexico`}
    />
  )
}
